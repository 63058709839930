<template>
  <v-list dense class="col-sm-6 col-12">
    <v-list-item dense v-for="(item, index) in list" :key="index">
      <v-list-item-title>{{ index }}:</v-list-item-title>
      <v-list-item-subtitle
        v-if="search.started"
        v-html="parsedHtml(search.text, association[item])"
      />
      <v-list-item-subtitle v-else>{{
        association[item]
      }}</v-list-item-subtitle>
    </v-list-item>
    <v-list-item>
      <v-list-item-title>Payments:</v-list-item-title>
      <v-list-item-subtitle>{{
        paymentAmount | moneyFormat
      }}</v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script>
import { moneyFormat } from "@/functions";
import { mapGetters } from "vuex";
export default {
  name: "report_case",
  filters: {
    moneyFormat
  },
  data: () => ({
    list: {
      "Owner Name": "Home Owner",
      "Property Address": "Client Address",
      "Matter Reference": "Matter Reference",
      "Matter Number": "Matter Number",
      Status: "Status"
    }
  }),
  props: {
    association: {
      type: Object,
      default: () => {}
    },
    paymentAmount: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    ...mapGetters({ search: "search" })
  }
};
</script>

<style scoped></style>
